import $ from 'jquery';  
window.jQuery = $; window.$ = $

import "slick-carousel/slick/slick";

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Style, Fill, Icon, Text } from 'ol/style';

(function( $ ) {
  
  $.fn.initMap = function() {
    return this.each(function(){
      const mapContainer = this
      let poiData = [];
      try {
        poiData = JSON.parse(mapContainer.getAttribute('data-pois') || '[]');
      } catch (error) {
        console.error('Error parsing POI data:', error);
      }

      // Create SVG marker style
      const svgMarker = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="30" viewBox="0 0 22 30"><path d="M21.31,15a19.48,19.48,0,0,1-3.47,6.09C13.66,26.28,11.27,29.6,11,30v0h0v0c-.26-.36-2.65-3.69-6.83-8.81a19.54,19.54,0,0,1-3.43-6,11,11,0,0,1-.71-3,7.29,7.29,0,0,1,0-.91V11.2a11,11,0,1,1,22-.38h0v.38h0a7,7,0,0,1-.06,1.11v.18A11,11,0,0,1,21.31,15ZM11.15,5.84a5.54,5.54,0,1,0,5.52,5.56s0,0,0,0h0a5.53,5.53,0,0,0-5.52-5.54h0Z" transform="translate(0 0)" style="fill:#333"/></svg>`;
    
      // Convert SVG to data URL
      const svgUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgMarker);
    
      
      // Create vector source for POI markers
      const poiSource = new VectorSource();

      // Create features for each POI
      poiData.forEach((poi) => {
        if (poi.coords && Array.isArray(poi.coords) && poi.coords.length === 2) {
          const feature = new Feature({
            geometry: new Point(fromLonLat(poi.coords)),
            title: poi.title,
            url: poi.url
          });

          // Add a style to make the point visible
          feature.setStyle(new Style({
            image: new Icon({
              src: svgUrl,
              scale: 1,
              anchor: [0.5, 1], // Position the bottom center of the icon at the feature's coordinates
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction'
            }),
            text: new Text({
              text: poi.title,
              offsetY: 8,
              font: '14px Graebenbach,sans-serif',
              fill: new Fill({ color: '#333' }),
              //stroke: new Stroke({ color: '#fff', width: 3 })
            })
          }));

          poiSource.addFeature(feature);
        }
      });

      // Create a vector layer with the POI source
      const poiLayer = new VectorLayer({
        source: poiSource
      });

      const map = new Map({
        target: mapContainer,
        layers: [
          // CARTO Positron layer
          new TileLayer({
              source: new XYZ({
                  url: 'https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
                  attributions: '© <a href="https://carto.com/attributions">CARTO</a> © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              })
          }),
          poiLayer
        ],
        view: new View({
          center: fromLonLat([10.4, 56.0]),
          zoom: 8
        })
      });

      // Add click handler to navigate to URLs
      map.on('click', function(evt) {
        // Get feature at click position
        const feature = map.forEachFeatureAtPixel(evt.pixel, function(feature) {
          return feature;
        }, { hitTolerance: 8 });
        
        // If a feature was clicked and has a URL, navigate to it
        if (feature && feature.get('url')) {
          window.location.href = feature.get('url');
        }
      });
      
      // Change cursor style when hovering over a feature
      map.on('pointermove', function(evt) {
        const pixel = map.getEventPixel(evt.originalEvent);
        const hit = map.hasFeatureAtPixel(pixel, { hitTolerance: 8 });
        map.getTargetElement().style.cursor = hit ? 'pointer' : '';
      });

      // Fit map view to show all POIs
      map.getView().fit(poiSource.getExtent(), {
        padding: [80, 80, 80, 80],
        duration: 1000
      });
    })
  }

})( jQuery );



$(function() {
  
  var content_divs = $(".tile img");

  $(document).scroll(function() {
    $('header .m').trigger('adjust:color', {divs: content_divs});
  });
  $('header .m').trigger('adjust:color', {divs: content_divs});
  
  window.addEventListener('popstate', function(e){
    if (e.state) {
      // e.state.href;  
    }      
  }); 
  
  
  $('.slick').slick({
    infinite: false,
    slidesToShow: 1.28,
    centerMode: false,
    dots: true,
    centerPadding: '0',  
    arrows: true,
    responsive: [
     {
       breakpoint: 599,
       settings: {
         slidesToShow: 1,
         arrows: true
       }
     }
   ]
  })

  $('.map-container').initMap();  

  // Video poster
  const reducedMotionMediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
  const videos = document.querySelectorAll('video')

  videos.forEach((video) => {
    const btn = video.nextElementSibling

    video.addEventListener('play', () => {
      video.classList.remove('paused')
    })

    video.addEventListener('pause', () => {
      video.classList.add('paused')
    })

    btn.addEventListener('click', (e) => {
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    })

    if (reducedMotionMediaQuery.matches) {
      video.pause()
      video.classList.add('paused')
    }
  })
  // Video poster
  
});

const imageObserver = new IntersectionObserver((entries, imgObserver) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const lazyImage = entry.target
      lazyImage.src = lazyImage.dataset.src
      lazyImage.classList.remove("lzy_img");
      imgObserver.unobserve(lazyImage);
    }
  })
});


// Lazy load project images
document.addEventListener("DOMContentLoaded", function() {
  const arr = document.querySelectorAll('#projects .lzy_img, #stories .lzy_img')
  arr.forEach((v) => {
    imageObserver.observe(v);
  })
})

$(document).on('click', "a[data-remote=true]", function(e){
  var $this = $(this),
      href = $this.attr('href'),
      target = $this.data('target');
      
  $.ajax({
    type: (($this.data('method') === undefined) ? 'GET' : $this.data('method')),
    url: href,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    success: function(html) {
      if (target !== undefined) {
        // $(target).replaceWith($(html).find(target));
        
        const newContent = $(html).find(target);  
        $(target).replaceWith(newContent);

        newContent.find('.map-container').each(function() {
          $(this).initMap();
        });

        const arr = document.querySelectorAll('#projects .lzy_img, #stories .lzy_img')
        arr.forEach((v) => {
          imageObserver.observe(v);
        })        
        
      }
    }  
  });
  
  window.history.pushState({href: href}, '', href);
  
  e.preventDefault();
});

$(document).on('submit', "form[data-remote=true]", function(e){
  var $this = $(this),
      href = $this.attr('action'),
      target = $this.data('target');
    
  $.ajax({
    type: (($this.data('method') === undefined) ? 'GET' : $this.data('method')),
    url: href,
    data: $this.serialize(),
    success: function(html) {
      
      if (target !== undefined) {
        
        const newContent = $(html).find(target);  
        $(target).replaceWith(newContent);

        newContent.find('.map-container').each(function() {
          $(this).initMap();
        });

                
        const arr = document.querySelectorAll('#projects .lzy_img, #stories .lzy_img')
        arr.forEach((v) => {
          imageObserver.observe(v);
        }) 
      }
    }  
  });

  e.preventDefault();  
});


$(document).on('adjust:color', "header .m", function(e, divs) {    
  var menu = $(this),
     above = null;
  
  divs['divs'].each(function() {
    if (is_colliding(menu, $(this))) {
      above = $(this)
      return;
    }
  });
  
  if (above != null && $('.cn', above.parents('.tile:first')).length > 0) {
    $("header").removeClass("light dark").addClass('light');
  } else {
    $("header").removeClass("light dark");
  }

});




$(document).on('change', "header input[type=checkbox]", function(e){  
  $('header').toggleClass('open-m')
  e.preventDefault();
});

var is_colliding = function( $div1, $div2 ) {
	// Div 1 data
	var d1_offset             = $div1.offset();
	var d1_height             = $div1.outerHeight( true );
	var d1_width              = $div1.outerWidth( true );
	var d1_distance_from_top  = d1_offset.top + d1_height;
	var d1_distance_from_left = d1_offset.left + d1_width;

	// Div 2 data
	var d2_offset             = $div2.offset();
	var d2_height             = $div2.outerHeight( true );
	var d2_width              = $div2.outerWidth( true );
	var d2_distance_from_top  = d2_offset.top + d2_height;
	var d2_distance_from_left = d2_offset.left + d2_width;

	var not_colliding = ( d1_distance_from_top < d2_offset.top || d1_offset.top > d2_distance_from_top || d1_distance_from_left < d2_offset.left || d1_offset.left > d2_distance_from_left );

	// Return whether it IS colliding
	return ! not_colliding;
};